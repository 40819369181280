import React from 'react';
import PropTypes from 'prop-types';

class DownloadLink extends React.Component{
  render(){return(
    <a
    href={this.props.url}
    // doesn't work on cross-origin hrefs: https://stackoverflow.com/questions/17527713/force-browser-to-download-image-files-on-click
    download={this.props.url}
    target="_parent"
    rel="noopener noreferrer">
      {this.props.text}
    </a>
  )}
}


DownloadLink.propTypes={
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
}

export default DownloadLink;
