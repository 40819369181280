import React from 'react';
import top_image from "./img/front.png";
import './App.css';
import SearchModule from "./SearchModule";
import SearchResultsModule from "./SearchResultsModule";

class App extends React.Component{
  state = {
    results: [],
    results_id: ""
  }

  didReceiveSearchResults = (results,results_id)=>{
    this.setState( { results, results_id } );
  }

  render(){return(
    <div className="App__background_container" align="center" onScroll={this.onScroll}>
      <div className="App__content_container" align="left">
        <div className="App__title_word_left">Rex</div>
        <div className="App__title_word_right">Dig</div>
        <div align="center">
          <img className="App__top_image" src={top_image} alt="logo" />
          < SearchModule
          didReceiveSearchResults={this.didReceiveSearchResults}
          />
          <div className="subheadline"> 100% free pixel art </div>
          < SearchResultsModule
          results={this.state.results}
          results_id={this.state.results_id}
          />
          <p className="footer">
            Rex Dig &copy; Skypeak LLC
          </p>
        </div>
      </div>
    </div>
  );}
}

export default App;
