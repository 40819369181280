import React from 'react';
import PropTypes from 'prop-types';
import axios from "axios";
import SearchModule_Options from "./SearchModule_Options";

const backend_host = "https://rexdig.com:9001";

class SearchModule extends React.Component {
  state={
    searching: false,
    keywords: "",
    required_tags: "",
    required_grid_size: ""
  }
  setRequiredTags=tags_array=>{
    const new_state = Object.create(this.state);
    new_state.required_tags = tags_array.join(" ");
    this.setState( new_state );
    // Might use more bandwidth, but provides a nicer user experience
    this.search( new_state );
  }
  setRequiredGrid=grid_size=>{
    const new_state = Object.create(this.state);
    new_state.required_grid_size = ""+grid_size;
    this.setState( new_state );
    // Might use more bandwidth, but provides a nicer user experience
    this.search( new_state );
  }
  search=(override_state=undefined)=>{
    const {
      keywords,required_tags, required_grid_size
    } = override_state || this.state;
    const query = "?k="+keywords +
      (required_tags==""?"":"&f="+required_tags) +
      (required_grid_size==""?"":"&g="+required_grid_size)
    ;
    this.setState({ searching: true});
    axios.get(backend_host+"/search/"+query)
    .then(response=>{
      this.props.didReceiveSearchResults( response.data, query );
    })
    .catch(error=>{
      console.log(error);
    })
    .finally( ()=>{
      this.setState({ searching: false});
    });
  }
  onKeyDown=event=>{
    // ENTER key
    if(event.which==13){
      this.search();
    }
  }
  onChange=event=>{
    this.setState({ keywords: event.target.value });
  }
  componentDidMount(){
    const url = ""+document.location;
    if( url.includes("?q=") ){
      const new_state = Object.create(this.state);
      new_state.keywords = url.split("?q=")[1];
      this.setState( new_state );
      this.search( new_state );
    }else{
      this.search();
    }
  }
  render(){
    return (
      <div>
        <div className={"ui search"+(this.state.searching?" loading":"")} id="search_ui_element">
          <div className="ui icon input">
            <input className="prompt"
            placeholder="dig for good, free, pixel art" type="text" id="search_prompt"
            value={this.state.keywords}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown} />
            <i className="search icon"></i>
          </div>
        </div>
        <SearchModule_Options
          setRequiredTags={this.setRequiredTags}
          setRequiredGrid={this.setRequiredGrid}
        />
      </div>
    )
  }
}

SearchModule.propTypes={
  didReceiveSearchResults: PropTypes.func.isRequired
}

export default SearchModule;
