import React from 'react';
import PropTypes from 'prop-types';
import SearchResultsModule_Result from "./SearchResultsModule_Result";
import './SearchResultsModule.css';

class SearchResultsModule extends React.Component {
  state = {
  }
  //incremented to show more results
  #n_results_to_show=0;
  #currently_displayed_results_id="";
  #refreshInfiniteScroll__last_content_added_timestamp_ms=0;
  componentDidMount(){
    window.addEventListener(
      "scroll",
      this.refreshInfiniteScroll
    );
    //start the loop
    this.refreshInfiniteScroll(500);
  }
  // This function is called on scroll, and also every 500 ms (for initial population)
  refreshInfiniteScroll=(loop_timeout=-1)=>{
    const bottom_of_page_y = document.getElementById("bottom_of_page").getBoundingClientRect().top;
    const bottom_of_screen_y = window.scrollY+window.innerHeight;
    if( bottom_of_page_y < bottom_of_screen_y ){
      const now_stamp_ms = 1*new Date();
      // Don't let content be added more than 2x per second.
      if(now_stamp_ms-this.#refreshInfiniteScroll__last_content_added_timestamp_ms > 500){
        this.#refreshInfiniteScroll__last_content_added_timestamp_ms = now_stamp_ms;
        // Add content to the bottom
        this.#n_results_to_show += 6;
        // trigger a re-render
        this.setState({});
      }
    }
    if(loop_timeout > -1){
      setTimeout(this.refreshInfiniteScroll.bind(this, loop_timeout), loop_timeout);
    }
  }
  render(){
    // Get the number of results to show, or in a case where
    // a new result set was given, reset the # of results to show
    if(this.props.results_id != this.#currently_displayed_results_id){
      this.#n_results_to_show = 6;
    }
    let n_results_to_show = this.#n_results_to_show;
    const {results} = this.props;
    let elements_to_render = [];
    for( let i=0 ; (i<results.length && i<n_results_to_show) ; ++i ){
      elements_to_render.push((
        <SearchResultsModule_Result record={results[i]} key={results[i].item_id} />
      ));
    }
    this.#currently_displayed_results_id = this.props.results_id
    return(
      <div>
      <div className="ui centered cards">
      {
        elements_to_render
      }
      </div>
      <div id="bottom_of_page" ></div>
      </div>
    )}
}

SearchResultsModule.propTypes={
  results: PropTypes.array.isRequired,
  //a string identifying the set of results that, if the results change, the string changes
  results_id: PropTypes.string.isRequired
}

export default SearchResultsModule;
