import React from 'react';
import PropTypes from 'prop-types';
import { Accordion, Icon } from "semantic-ui-react";

// resources:
// https://react.semantic-ui.com/modules/accordion/#types-standard

class SearchModule_Options extends React.Component {
  state={
    activeIndex: -1,
    option_type: "",
    option_view: "",
    option_outline: "",
    option_animated: "",
    grid_size: ""
  }

  handleClick=(e,titleProps)=>{
    const { index } = titleProps
    const newIndex = this.state.activeIndex == index ? -1 : index
    this.setState({ activeIndex: newIndex })
  }
  onChangeGrid=event=>{
    const grid_size = event.target.value;
    this.setState({grid_size});
    this.props.setRequiredGrid( grid_size);
  }
  onChange=event=>{
    const new_state = Object.create(this.state);
    new_state[event.target.name] = event.target.value;
    this.setState(new_state);
    this.props.setRequiredTags(
      [
        new_state.option_type,
        new_state.option_view,
        new_state.option_outline,
        new_state.option_animated,
      ]
      .filter(tag=>tag!="")
    );
  }

  render(){
    return (
      <Accordion>
        <Accordion.Title
          active={this.state.activeIndex == 0}
          index={0}
          onClick={this.handleClick}
        >
          <Icon name="dropdown" />
          options
        </Accordion.Title>
        <Accordion.Content
          active={this.state.activeIndex == 0}
        >
          <select
            className="ui dropdown"
            name="grid_size"
            onChange={this.onChangeGrid}
          >
            <option value="">Any Grid</option>
            <option value="8">8x8</option>
            <option value="16">16x16</option>
            <option value="32">32x32</option>
          </select>
          <select
            className="ui dropdown"
            name="option_type"
            onChange={this.onChange}
          >
            <option value="">Any Type</option>
            <option value="sprite">Sprite</option>
            <option value="tileset">Tileset</option>
          </select>
          <select
            className="ui dropdown"
            name="option_view"
            onChange={this.onChange}
          >
            <option value="">Any View</option>
            <option value="three-fourths-ortho">3/4ths Orthographic</option>
            <option value="sidescroller">Sidescroller</option>
            <option value="iso">Isometric</option>
            <option value="top-down">Top-Down</option>
            <option value="orthographic">Orthographic</option>
          </select>
          <select
            className="ui dropdown"
            name="option_outline"
            onChange={this.onChange}
          >
            <option value="">Outlined or Not</option>
            <option value="outline">Outline</option>
            <option value="lineless">No Outline</option>
          </select>
          <select
            className="ui dropdown"
            name="option_animated"
            onChange={this.onChange}
          >
            <option value="">Animated or Not</option>
            <option value="animated">Animated</option>
            <option value="static">Not Animated</option>
          </select>
        </Accordion.Content>
      </Accordion>
    )
  }
}

SearchModule_Options.propTypes={
  setRequiredTags: PropTypes.func.isRequired,
  setRequiredGrid: PropTypes.func.isRequired
}

export default SearchModule_Options;
