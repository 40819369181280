import React from 'react';
import PropTypes from 'prop-types';
import { Card, Image, Modal, Button } from "semantic-ui-react"
import './SearchResultsModule_Result.css';
import DownloadLink from './DownloadLink';
import { v4 as uuidv4 } from 'uuid';
const backend_host = "https://rexdig.com:9001";

class SearchResultsModule_Result extends React.Component {
  state = {
    modalOpen: false
  }
  // Simple title cleanup with capitalization and a couple exceptions
  treat_title=title=>{
    return title.replace(/ - /g," ~~~ ")//save " - " to temporary so it doens't get nuked when we sanitize the hypens
    .replace(/-/g," ")
    .split(" ")
    .reduce(
      (title,word)=>{
        word = word.split("");
        if(word.length>0){
          word[0] = word[0].toUpperCase();
        }
        word = word.reduce((acc,cur)=>acc+cur,"");
        if( word.length==2 ){//UI, GB, etc...
          word = word.toUpperCase();
        }
        if( ["of", "in", "or"].includes(word.toLowerCase()) ){
          word = word.toLowerCase();
        }
        return title=="" ? word : title+" "+word;
      },
      ""
    )
    .replace(/ ~~~ /g," - ")//put the " - " back in
    ;
  }
  getLicenseLI=record=>{
    if( record.license == "CC0"){
      return(
        <li key={uuidv4()}>100% Free License: <a target="_blank" rel="noopener noreferrer" href="http://creativecommons.org/publicdomain/zero/1.0/"> Public Domain (CC0)</a></li>
      )
    }
  }
  getAuthorLI=record=>{
    const href = `https://duckduckgo.com/?q=${record.author}+pixel+artist`;
    return(
      <li>Author: <a target="_blank" rel="noopener noreferrer" href={href} key={uuidv4()}>{record.author}</a></li>
    )
  }
  getDownloadLIs=record=>{
    const src_sprite = backend_host+"/image/sprite/?key="+record.item_key;
    const zip_src_sprite = backend_host+"/zip/sprite/?key="+record.item_key;
    const src_strip = backend_host+"/image/strip/?key="+record.item_key;
    const zip_src_strip = backend_host+"/zip/strip/?key="+record.item_key;
    const zip_filename = record.item_key + ".zip";
    const image_filename = record.item_key + ".png";
    const animations = record.animations==""?[""]:record.animations.split(" ");
    const n_frames = record.animations_n_frames.split(" ").map(n=>Number(n));

    // Single image scenario
    if( animations.length==1 && n_frames[0]==1 ){
      return (
        <li key={uuidv4()}> Download: <DownloadLink url={src_sprite} text={image_filename}/> </li>
      );
    }
    // Multi file scenario (else for clarity)
    else {
      let re = [];
      // If it would be single-image as a strip, offer the strip
      if( animations.length == 1){
        re.push((
         <li key={uuidv4()}> Download (animation strip): <DownloadLink url={src_strip} text={image_filename}/> </li>
        ));
      }
      // (always) offer singles zip
      re.push((
       <li key={uuidv4()}> Download (file per sprite): <DownloadLink url={zip_src_sprite} text={zip_filename}/> </li>
      ));
      // If it would be multi-image as a strip, offer the zip (and make sure strips aren't redundant with the 1 file per sprite option)
      if( animations.length > 1 && !n_frames.every(n=>n==1) ){
        re.push((
         <li key={uuidv4()}> Download (animation strips): <DownloadLink url={zip_src_strip} text={zip_filename}/> </li>
        ));
      }
      return re;
    }
  }
  getAnimationNamesLIs=record=>{
    const animations = record.animations==""?[""]:record.animations.split(" ");
    if(animations.length==1){
      return [];
    }
    // const n_frames = record.animations_n_frames.split(" ").map(n=>Number(n));
    const animation_names = "\""+animations.join("\", \"")+"\"";
    return (
      <li>Animations ({animations.length} total): {animation_names}</li>
    );
  }
  openModal=()=>{
    this.setState({ modalOpen: true})
  }
  closeModal=()=>{
    this.setState({ modalOpen: false})
  }
  render(){
      const record = this.props.record;
      // const author = record.author;
      const preview_img_src = backend_host+"/preview/?key="+record.item_key;

      const title = this.treat_title(
        // Some sets are just 1 item, in which case there's no item name
        record.item==""?record.set : record.set+" "+record.item
      );
      const modal_title = this.treat_title(
        record.item==""?record.set : record.set+": "+record.item
      );
      // Add the record's basic tags so CSS can use them, too
      const image_classlist = "image_pixel_art"+" "+record.tags;
      return(
        <Modal
        open={this.state.modalOpen}
        onOpen={this.openModal}
        onClose={this.closeModal}
        trigger={
          <Card
           key={record.item_id}
           >
            <div className="ui blurring image">
              <div className="ui center aligned dimmer">
                <div className="ui inverted button" >
                  Download
                </div>
              </div>
              <img src={preview_img_src} className={image_classlist} alt={title} />
            </div>
            <div className="item_description ui center aligned">{title}</div>
          </Card>
        }>

        <Modal.Header>{modal_title}</Modal.Header>
        <Modal.Content image>
          <Image className={image_classlist} src={preview_img_src} />
          <Modal.Description>
            <ul>
              {this.getAuthorLI(record)}
              {this.getAnimationNamesLIs(record)}
              {this.getLicenseLI(record)}
              {this.getDownloadLIs(record)}
            </ul>
            <Button onClick={this.closeModal} content="Close" />
          </Modal.Description>
        </Modal.Content>
      </Modal>
      );
    }
}

SearchResultsModule_Result.propTypes={
  record: PropTypes.object.isRequired
}

export default SearchResultsModule_Result;
